import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';
import { type Theme, createTheme } from '@mui/material/styles';

const colorBody = '#e3e3e3';
const colorPaper = '#e3e3e3';
declare module '@mui/material/styles' {
	interface PaletteColor {
		percent35?: string;
		percent50?: string;
	}
	interface SimplePaletteColorOptions {
		percent35?: string;
		percent50?: string;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		blue: true;

		ButtonAction: true;
		ButtonAdd: true;
		ButtonEdit: true;
		ButtonDelete: true;
		ButtonActionText: true;
		ButtonNavbarActive: true;
		ButtonNavbarAction: true;
	}
}
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		TitleSection: true;
		BodySection: true;
		TitleCard: true;
		MenuLink: true;
		MenuLinkPrimary: true;
		Subtitle: true;
		Body1: true;
	}
}

const PRIMARY_COLOR_PALETTE = {
	main: '#0070b3',
	light: '#0070b380',
	dark: '#0080b4',
	contrastText: '#ffffff',
};
const SECONDARY_COLOR_PALETTE = {
	main: '#19255b',
	light: '#19255b80',
	dark: '#19258b',
	contrastText: '#ffffff',
};

const WHITE_COLOR_PALETTE = {
	main: '#ffffff',
	light: '#ffffff90',
	dark: '#f9f9f9',
	contrastText: '#19255b',
};

export const ThemePalette = createTheme({
	palette: {
		primary: PRIMARY_COLOR_PALETTE,
		secondary: SECONDARY_COLOR_PALETTE,
		white: WHITE_COLOR_PALETTE,
		background: {
			default: colorBody,
			paper: colorPaper,
		},
	},
});

const extendTheme = (theme: Theme) => {
	theme.typography.h1 = {
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '2rem',
		lineHeight: '2.8rem',
	};
	theme.typography.h2 = {
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '24px',
	};
	theme.typography.h3 = {
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '24px',
	};
	theme.typography.h4 = {
		fontFamily: 'Lato',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '16px',
		lineHeight: '24px',
	};
	theme.typography.h5 = {
		fontFamily: 'Lato',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '18px',
		lineHeight: '24px',
	};
	theme.typography.h6 = {
		fontFamily: 'Lato',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '16px',
		lineHeight: '24px',
	};

	theme.typography.body1 = {
		fontFamily: 'Lato',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '24px',
	};

	return theme;
};

export const ThemeBasic = extendTheme(
	createTheme(ThemePalette, {
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: '8px',
						fontWeight: 'bold',
						transition: 'ease all .4s',
						'&:hover': {
							transition: 'ease all .4s',
						},
						'&:disabled': {
							cursor: 'not-allowed',
							color: ThemePalette.palette.primary.dark,
							background: ThemePalette.palette.primary.light,
							border: 'none',
						},
					},
				},
				variants: [
					{
						props: { variant: 'ButtonActionText' },
						style: {
							color: ThemePalette.palette.primary.main,
							fontSize: '1rem',
							textTransform: 'initial',

							'&:hover': {
								fontWeight: 'bold',
								background: 'transparent',
								textDecoration: 'underline',
							},
						},
					},
					{
						props: { variant: 'ButtonNavbarActive' },
						style: {
							color: ThemePalette.palette.secondary.main,
							background: ThemePalette.palette.primary.main,

							'&:hover': {
								color: ThemePalette.palette.primary.main,
								background: 'transparent',
								borderColor: ThemePalette.palette.primary.main,
								transform: 'scale(1.03)',
							},
						},
					},
					{
						props: { variant: 'ButtonAction' },
						style: {
							border: `2px solid ${ThemePalette.palette.primary.main}`,
							borderRadius: '5px',
							textTransform: 'Capitalize',
							fontWeight: 'bold',
							color: ThemePalette.palette.secondary.main,
							background: ThemePalette.palette.primary.main,
							transition: 'ease all .4s',
							'&:hover': {
								color: ThemePalette.palette.primary.main,
								background: 'transparent',
								transform: 'scale(1.03)',
							},
						},
					},
					{
						props: { variant: 'ButtonAdd' },
						style: {
							color: ThemePalette.palette.secondary.main,
							background: ThemePalette.palette.primary.main,

							'&:hover': {
								color: ThemePalette.palette.primary.main,
								background: 'transparent',
								transform: 'scale(1.03)',
							},
						},
					},
					{
						props: { variant: 'ButtonEdit' },
						style: {
							color: ThemePalette.palette.secondary.main,
							background: ThemePalette.palette.warning.main,
							borderColor: ThemePalette.palette.warning.main,
							padding: '0px',
							'&:hover': {
								color: ThemePalette.palette.primary.main,
								background: 'transparent',
								transform: 'scale(1.03)',
							},
						},
					},
					{
						props: { variant: 'ButtonDelete' },
						style: {
							color: ThemePalette.palette.secondary.main,
							background: ThemePalette.palette.error.main,
							borderColor: ThemePalette.palette.error.main,
							padding: '0px',

							'&:hover': {
								color: ThemePalette.palette.primary.main,
								background: 'transparent',
								transform: 'scale(1.03)',
							},
						},
					},
					{
						props: { variant: 'ButtonNavbarAction' },
						style: {
							border: `.5px solid ${ThemePalette.palette.primary.main}`,
							minHeight: '35px',
							minWidth: '35px',
							borderRadius: '0 20px 0 20px',
							color: ThemePalette.palette.primary.main,
							svg: {
								color: `${ThemePalette.palette.secondary.main} !important`,
								width: '2rem',
								height: '2rem',
							},
							'&:hover': {
								backgroundColor: ThemePalette.palette.primary.main,
								color: ThemePalette.palette.primary.main,
								svg: {
									color: `${
										ThemePalette.palette.white?.main || 'white'
									} !important`,
								},
							},
						},
					},
				],
			},
			MuiTypography: {
				styleOverrides: {
					root: {},
				},
				defaultProps: {
					variantMapping: {
						TitleSection: 'h1',
						Subtitle: 'h6',
						BodySection: 'p',
						Body1: 'p',
					},
				},

				variants: [
					{
						props: { variant: 'TitleSection' },
						style: {
							fontSize: 'clamp(1rem,3vw, 1.5rem)',
							color: ThemePalette.palette.secondary.main,
						},
					},
					{
						props: { variant: 'TitleCard' },
						style: {
							fontSize: 'clamp(1rem,1.6vw, 2rem)',
							color: ThemePalette.palette.secondary.main,
						},
					},
					{
						props: { variant: 'MenuLink' },
						style: {
							fontSize: 'clamp(1rem,1.4vw, 1.8rem)',
							color: ThemePalette.palette.secondary.main,
						},
					},
					{
						props: { variant: 'MenuLinkPrimary' },
						style: {
							fontSize: 'clamp(1rem,1.4vw, 1.8rem)',
							color: ThemePalette.palette.primary.main,
						},
					},
					{
						props: { variant: 'BodySection' },
						style: {
							fontSize: 'clamp(0.8rem,3vw, 1.3rem)',
							color: ThemePalette.palette.secondary.main,

							letterSpacing: 1.8,
							margin: '5px 0',
							a: {
								color: ThemePalette.palette.secondary.main,
							},
						},
					},
					{
						props: { variant: 'Subtitle' },
						style: {
							fontSize: 'clamp(0.8rem,1.4vw, 1.3rem)',
							color: ThemePalette.palette.secondary.main,
							lineHeight: '2rem',
							letterSpacing: 1.8,
							margin: '5px 0',
							a: {
								color: ThemePalette.palette.secondary.main,
							},
						},
					},
					{
						props: { variant: 'Body1' },
						style: {
							fontSize: 'clamp(0.7rem,1.4vw, 1rem)',
							color: ThemePalette.palette.secondary.main,
							lineHeight: '2rem',
							letterSpacing: 1.8,
							margin: '5px 0',
							a: {
								color: ThemePalette.palette.secondary.main,
							},
						},
					},
				],
			},
			MuiCssBaseline: {
				styleOverrides: () => ({
					'.ListItemButton': {
						color: `${ThemePalette.palette.secondary.main} !important`,
					},
					'.listItemCategoricFilter': {
						background: 'transparent',
					},
				}),
			},
		},
	})
);
export default ThemeBasic;
