'use client';
import { useClientTranslation } from '@negotium/common';
import {
	Alert,
	AlertTitle,
	Container,
	Grid,
	Paper,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IProductCatalog } from '@negotium/models';
import {
	LoadingRectangle,
	useNotification,
} from '@negotium/componentsCustomer';
import { getWishList } from '@negotium/libCustomer';
import { CardProduct } from '../CardProduct';

type ChangePasswordProps = {
	lng: 'es' | 'en';
};

export default function WishList({ lng }: ChangePasswordProps) {
	const { t } = useClientTranslation(lng, ['wishList']);

	const { setErrorNotification } = useNotification();
	const [loading, setLoading] = useState<boolean>(true);
	const [productsCatalogs, setProductsCatalogs] = useState<
		Array<IProductCatalog> | []
	>([]);

	useEffect(() => {
		setLoading(true);
		getWishList(10, 0)
			.then((result) => {
				setProductsCatalogs([...result.docs]);
				setLoading(false);
			})
			.catch((err) => {
				setErrorNotification(err.message);
				setLoading(false);
			});
	}, []);
	function setWishListUserLogged(data: boolean, index: number) {
		const aux = productsCatalogs;
		aux[index] = {
			...aux[index],
			whishListUserLogged: data,
		};
		setProductsCatalogs([...aux]);
	}
	return (
		<Paper elevation={2}>
			<Container
				maxWidth='xl'
				sx={{
					pt: 1,
					pb: 5,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							variant='TitleSection'
							fontSize='clamp(1rem,3vw,1.8rem)'
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<FavoriteIcon
								fontSize='large'
								color='primary'
								sx={{ marginRight: '1rem' }}
							/>
							{t('wishList')}
						</Typography>
					</Grid>
					{loading &&
						[...Array(3).keys()].map((item) => (
							<Grid item xs={12} md={4} key={item}>
								<LoadingRectangle width={'100%'} />
							</Grid>
						))}

					{productsCatalogs.length === 0 && !loading && (
						<Grid item xs={12}>
							<Alert
								sx={{
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
									svg: { width: '3rem', height: '3rem' },
								}}
								title='test'
								color='info'
								severity='info'
							>
								<AlertTitle
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									{t('noElement')}
								</AlertTitle>
							</Alert>
						</Grid>
					)}
					{productsCatalogs.length > 0 &&
						!loading &&
						productsCatalogs.map((item, index) => (
							<Grid item xs={12} md={4} key={item.productId}>
								<CardProduct
									product={item}
									lng={lng}
									setWishListUserLogged={(data) =>
										setWishListUserLogged(data, index)
									}
								/>
							</Grid>
						))}
				</Grid>
			</Container>
		</Paper>
	);
}
