import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormGroup,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Slide,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
	FormControl,
	Divider,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useNotification } from '../ErrorContext';
import useAuthFormData from './hooks/useAuthFormData';
import { AxiosError } from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { encryptAES, useClientTranslation } from '@negotium/common';
import { IAuth } from '@negotium/models';
import { signIn } from '@negotium/libCustomer';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

type LoginProps = {
	open: boolean;
	onClose: () => void;
	lng: 'es' | 'en';
	onOpenRegister: () => void;
	onOpenForgetPassword: () => void;
};

export const Login = ({
	open,
	onClose,
	lng = 'es',
	onOpenRegister,
	onOpenForgetPassword,
}: LoginProps) => {
	const { t } = useClientTranslation(lng, ['common']);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [loadingAuth, setLoadingAuth] = useState(false);
	const { setErrorNotification } = useNotification();
	const [showPassword, setShowPassword] = useState(false);
	const initialValues = {
		email: '',
		password: '',
	};
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const onSubmit = async (values: IAuth): Promise<void> => {
		try {
			setLoadingAuth(true);

			let password: string = values.password;
			if (values?.password && process.env.NEXT_PUBLIC_ENCRYPT_KEY) {
				password = encryptAES(
					values.password,
					process.env.NEXT_PUBLIC_ENCRYPT_KEY
				);
			}
			const result = await signIn({
				email: values?.email,
				password,
			});
			if (result) {
				setLoadingAuth(false);
				onClose();
			}
		} catch (err) {
			if (err instanceof AxiosError) {
				setErrorNotification(t(`${err?.response?.data.message}`));
			}
			setLoadingAuth(false);
		}
	};

	const { formik, isLoading } = useAuthFormData(initialValues, onSubmit, lng);

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-describedby='alert-dialog-slide-description'
			fullScreen={fullScreen}
			PaperProps={{
				sx: {
					backgroundColor: theme.palette.background.default,
					py: 2,
					borderRadius: '0',
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						background: (theme) => theme.palette.primary.light,
						backdropFilter: 'blur(10px)',
					},
				},
			}}
		>
			<DialogTitle>
				<Typography
					variant='TitleSection'
					textAlign='center'
					fontSize='clamp(1.5rem,3.6vw, 2rem)'
				>
					{t('login')}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container marginTop={2}>
						<Grid container rowSpacing={1}>
							<Grid item xs={12}>
								<FormGroup>
									<TextField
										label={t('email')}
										id='email'
										placeholder='Eg. johndoe@gmail.com'
										value={formik.values.email}
										onChange={(e) => formik.handleChange(e)}
										onBlur={formik.handleBlur}
										error={formik.touched.email && Boolean(formik.errors.email)}
										helperText={
											formik.touched.email &&
											formik.errors.email &&
											t(String(formik.errors.email))
										}
										fullWidth={false}
										margin='dense'
										color='secondary'
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={12}>
								<FormGroup>
									<FormControl>
										<InputLabel htmlFor='password' color='secondary'>
											{t('password')}
										</InputLabel>
										<OutlinedInput
											id='password'
											label={t('password')}
											type={showPassword ? 'text' : 'password'}
											color='secondary'
											endAdornment={
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge='end'
														color='primary'
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											}
											placeholder='********'
											value={formik.values.password}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											error={
												formik.touched.password &&
												Boolean(formik.errors.password)
											}
											fullWidth={false}
											margin='dense'
										/>
										{formik.errors.password && formik.touched.password && (
											<FormHelperText sx={{ color: '#d32f2f' }}>
												{t(formik.errors.password)}
											</FormHelperText>
										)}
									</FormControl>
								</FormGroup>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								marginTop: '20px',
							}}
						>
							<LoadingButton
								fullWidth
								type='submit'
								autoFocus
								loading={loadingAuth || isLoading}
								loadingPosition='end'
								variant='ButtonNavbarActive'
								sx={{
									borderRadius: 1,
								}}
								disabled={isLoading || loadingAuth}
							>
								{t('login')}
							</LoadingButton>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								marginTop: '15px',
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Button
								autoFocus
								onClick={() => onOpenForgetPassword()}
								variant='ButtonActionText'
							>
								{t('forgotPass')}
							</Button>
						</Grid>

						<Grid xs={12} item>
							<Divider
								sx={{
									borderColor: (theme) => theme.palette.secondary.light,
									borderWidth: 1,
									my: 2,
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Button
								autoFocus
								onClick={() => onOpenRegister()}
								variant='ButtonActionText'
							>
								{t('notHaveAccount')}
							</Button>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
};
