'use client';
import { useClientTranslation } from '@negotium/common';

import { LoadingButton } from '@mui/lab';
import {
	Container,
	FormGroup,
	Grid,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import useUserFormDataClient from './useUserFormDataClient/useUserFormDataClient';
import { useNotification } from '@negotium/componentsCustomer';
import { usersEditProfile, usersGetProfile } from '@negotium/libCustomer';
import { IProfileEdit } from '@negotium/models';

type ProfileProps = {
	lng: 'es' | 'en';
};

export default function Profile({ lng }: ProfileProps) {
	const { t } = useClientTranslation(lng, ['users', 'common']);

	const [refresh, setRefresh] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);
	const { setErrorNotification, setSuccessNotification } = useNotification();

	const initialValues = {
		userId: v4(),
		name: '',
		lastName: '',
		email: '',
		phone: '',
		ci: '',
	};

	useEffect(() => {
		if (refresh) {
			usersGetProfile()
				.then((result) => {
					if (result.data) {
						formik.setValues(result?.data);
					}
					setRefresh(false);
				})
				.catch((err) => {
					setErrorNotification(err.message);
				});
		}
	}, [refresh]);

	const onSubmit = async (values: IProfileEdit): Promise<void> => {
		setLoading(true);

		usersEditProfile(values)
			.then((result) => {
				if (result.status === 200) {
					setSuccessNotification(t('profileEditedSuccessfully'));
					setRefresh(true);
					setLoading(false);
				} else {
					setErrorNotification(t(`${result.message}`));
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				setErrorNotification(err.message);
			});
	};

	const { formik, isLoading } = useUserFormDataClient(
		initialValues,
		onSubmit,
		lng
	);

	return (
		<Paper elevation={2}>
			<Container
				maxWidth='xl'
				sx={{
					pt: 1,
					pb: 5,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							variant='TitleSection'
							fontSize='clamp(1rem,3vw,1.8rem)'
						>
							{t('profile', { ns: ['common'] })}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<form onSubmit={formik.handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<FormGroup>
										<TextField
											color='secondary'
											label={t('name', { ns: 'users' })}
											id='name'
											placeholder='Ej. John'
											fullWidth={false}
											margin='dense'
											name='name'
											value={formik.values.name}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											error={formik.touched.name && Boolean(formik.errors.name)}
											helperText={formik.touched.name && formik.errors.name}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormGroup>
										<TextField
											color='secondary'
											label={t('lastName', { ns: 'users' })}
											id='lastName'
											placeholder='Ej. Doe'
											fullWidth={false}
											margin='dense'
											name='lastName'
											value={formik.values.lastName}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											error={
												formik.touched.lastName &&
												Boolean(formik.errors.lastName)
											}
											helperText={
												formik.touched.lastName && formik.errors.lastName
											}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={6}>
									<FormGroup>
										<TextField
											color='secondary'
											label={t('email', { ns: 'users' })}
											id='email'
											placeholder='user@NEXT_PUBLIC_DOMAIN.xxx'
											fullWidth={false}
											margin='dense'
											name='email'
											value={formik.values.email}
											disabled
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormGroup>
										<TextField
											color='secondary'
											label={t('phone', { ns: 'users' })}
											id='phone'
											placeholder=''
											fullWidth={false}
											margin='dense'
											name='phone'
											value={formik.values.phone}
											onChange={(e) => formik.handleChange(e)}
											onBlur={formik.handleBlur}
											error={
												formik.touched.phone && Boolean(formik.errors.phone)
											}
											helperText={formik.touched.phone && formik.errors.phone}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12}>
									<LoadingButton
										fullWidth
										type='submit'
										autoFocus
										loading={loading || isLoading}
										disabled={loading || isLoading}
										loadingPosition='end'
										title={t('updated')}
										variant='ButtonNavbarActive'
										sx={{
											borderRadius: 1,
											mt: 1.5,
										}}
										aria-disabled={isLoading || loading}
									>
										{t('updated')}
									</LoadingButton>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</Container>
		</Paper>
	);
}
