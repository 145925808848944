'use client';
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	IconButton,
	TextField,
	Typography,
} from '@mui/material';
import { IProductCatalog } from '@negotium/models';
import React, { useState } from 'react';
import { ImageWithFallback } from '../ImageWithFallback';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useClientTranslation } from '@negotium/common';
import { addUserToWhishList } from 'apps/customer-portal/app/lib/product/actions';
import { useNotification } from '../ErrorContext';
import Link from 'next/link';
import { Rating } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css';
type CardProductProps = {
	product: IProductCatalog;
	lng: 'es' | 'en';
	setWishListUserLogged: (data: boolean) => void;
	onAddProduct?: (storageUniqueId: string, amount: number) => void;
};

export const CardProduct = ({
	product,
	lng,
	onAddProduct,
	setWishListUserLogged,
}: CardProductProps) => {
	const { t } = useClientTranslation(lng, ['catalog']);
	const { setErrorNotification, setSuccessNotification } = useNotification();
	const [rating, setRating] = useState(4);

	function onChange(newValue: number) {
		console.log(newValue);
		setRating(newValue);
	}
	const [amount, setAmount] = useState<number>(1);

	const handleIncrement = () => {
		setAmount(amount + 1);
	};

	const handleDecrement = () => {
		if (amount > 1) {
			setAmount(amount - 1);
		}
	};
	function handleSave() {
		if (product && onAddProduct) {
			onAddProduct(product?.storageUniqueId, amount);
		}
	}
	function addOrRemoveFavorite(storageUniqueId: string) {
		addUserToWhishList(storageUniqueId)
			.then((result) => {
				console.log('result?.data', result?.data);
				setWishListUserLogged(result?.data || false);
				setSuccessNotification(
					t(
						result?.data
							? 'successToProductAddedWishList'
							: 'successToProductRemoveWishList'
					)
				);
			})
			.catch(() => {
				setErrorNotification(t('errorToProductAddedWishList'));
			});
	}

	return (
		<Card
			sx={{
				background: 'white',
				borderRadius: 1,
				minHeight: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				position: 'relative',
			}}
		>
			<Link
				href={`/product/${product.productId}`}
				target='_blank'
				style={{
					textDecoration: 'none',
				}}
			>
				<Box width={{ xs: '100%', md: '100%' }}>
					<ImageWithFallback
						src={product.productImage.url}
						fallbackImage='/static/facebook600x315.png'
						alt={product.productName[lng]}
						width={product.productImage.width}
						height={product.productImage.height}
						style={{ width: '100%', height: 'auto' }}
					/>
				</Box>
				<CardContent sx={{ paddingBottom: '0px !important' }}>
					<CardHeader
						title={product.productName[lng]}
						titleTypographyProps={{
							variant: 'TitleSection',
							fontSize: '1.3rem',
							letterSpacing: 1,
							fontWeight: '600',
						}}
					/>
					<Box display='flex' justifyContent='space-between'>
						<Typography
							variant='BodySection'
							fontSize='1rem'
							letterSpacing={1}
							fontWeight='600'
							sx={{ color: 'text.secondary', lineHeight: '1rem' }}
							margin='25px 0 5px 0'
						>
							{`${product.productSalePrice} ${product.productCurrency}`}
						</Typography>
					</Box>
					<Box display='flex' justifyContent='space-between'>
						<Rating
							style={{ maxWidth: 180 }}
							value={rating}
							onChange={onChange}
							transition='zoom'
						/>
						<IconButton
							aria-label={t('addToWishList')}
							color='primary'
							size='large'
							title={t('addToWishList')}
							onClick={() => addOrRemoveFavorite(product.storageUniqueId)}
						>
							{product?.whishListUserLogged ? (
								<FavoriteIcon fontSize='large' />
							) : (
								<FavoriteBorderIcon fontSize='large' />
							)}
						</IconButton>
					</Box>

					{product.productTags[lng] && (
						<Box
							display='flex'
							justifyContent='flex-end'
							position='absolute'
							top='2%'
							right='2%'
						>
							<Chip
								label={product.productTags[lng]}
								color='success'
								variant='filled'
							/>
						</Box>
					)}
				</CardContent>
			</Link>

			<CardActions
				disableSpacing
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<ButtonGroup
						sx={{
							height: '30px',
						}}
						variant='text'
						aria-label={t('changeAmountOfProduct')}
						title={t('changeAmountOfProduct')}
					>
						<Button
							onClick={handleDecrement}
							aria-label={t('decrementAmount')}
							title={t('decrementAmount')}
							disabled={amount === 1}
							color='primary'
							variant='outlined'
							sx={{ width: '2.8rem' }}
						>
							-
						</Button>
						<TextField
							type='number'
							value={amount}
							inputProps={{
								sx: {
									minHeight: '30px',
									py: 0,
									px: 1,
									borderRadius: 0,
									textAlign: 'center',
									justifyContent: 'center',
									display: 'flex',
									'-moz-appearance': 'textfield',
									'::-webkit-outer-spin-button': {
										'-webkit-appearance': 'none',
										margin: 0,
									},
									'::-webkit-inner-spin-button': {
										'-webkit-appearance': 'none',
										margin: 0,
									},
								},
							}}
							onChange={(e) => setAmount(Number(e.target.value))}
							sx={{
								width: '2.8rem',

								borderRadius: 0,
								'&>*': {
									'&.MuiOutlinedInput-root': {
										borderRadius: 0,
									},
									fieldset: {
										borderColor: (theme) => theme.palette.primary.main,
									},
								},
							}}
						/>
						<Button
							onClick={handleIncrement}
							aria-label={t('incrementAmount')}
							title={t('incrementAmount')}
							sx={{ width: '2.8rem' }}
							color='primary'
							variant='outlined'
						>
							+
						</Button>
					</ButtonGroup>
					<IconButton
						sx={{
							ml: 1,
						}}
						aria-label={t('addCartShop')}
						title={t('addCartShop')}
						size='large'
						color='warning'
						onClick={() => handleSave()}
					>
						<AddShoppingCartIcon fontSize='large' />
						{t('add')}
					</IconButton>
				</Box>
			</CardActions>
		</Card>
	);
};
