'use client';
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { IAdvertisingShow } from '@negotium/models';
import { useNotification } from '../ErrorContext';
import { getAdvertisementsActives } from '@negotium/libCustomer';
import { ImageWithFallback } from '../ImageWithFallback';
import dynamic from 'next/dynamic';
import Slider from 'react-slick';
import { LoadingRectangle } from '../Skeleton';

type AdvertisingProps = {
	lng: 'es' | 'en';
};

const ArrowSlider = dynamic(
	() =>
		import('@negotium/componentsCustomer').then((module) => module.ArrowSlider),
	{ ssr: false }
);

export const Advertising = ({ lng }: AdvertisingProps) => {
	const { t } = useClientTranslation(lng, ['common']);

	const [advertisements, setAdvertisements] = useState<Array<IAdvertisingShow>>(
		[]
	);

	const [loadingAdvertisements, setLoadingAdvertisements] =
		useState<boolean>(true);
	const { setErrorNotification } = useNotification();

	useEffect(() => {
		if (loadingAdvertisements) {
			getAdvertisementsActives()
				.then((response) => {
					setAdvertisements(response.docs);
					setLoadingAdvertisements(false);
				})
				.catch(() => {
					setLoadingAdvertisements(false);
					setErrorNotification(t('errorGetAdvertisements'));
				});
		}
	}, [loadingAdvertisements]);

	if (loadingAdvertisements) {
		return (
			<Container
				maxWidth='xl'
				disableGutters
				sx={{
					height: '55vh',
					overflow: 'hidden',
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<LoadingRectangle width={'100%'} />
					</Grid>
				</Grid>
			</Container>
		);
	}

	if (advertisements.length === 0) {
		return null;
	}

	const settings = {
		dots: false,
		infinite: advertisements.length > 1,
		speed: 500,
		autoPlay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		nextArrow: <ArrowSlider right={true} />,
		prevArrow: <ArrowSlider right={false} />,
	};

	if (advertisements.length === 0) {
		return null;
	}

	return (
		<Container
			maxWidth='xl'
			disableGutters
			sx={{
				height: '55vh',
			}}
		>
			<Grid container>
				<Grid item xs={12}>
					<Slider {...settings}>
						{[...advertisements].map((advertising, index) => {
							return (
								<div key={`${index}-${advertising.advertisingId}`}>
									<Box position='relative' width={{ xs: '100%', md: '100%' }}>
										<Box width={{ xs: '100%', md: '100%' }} position='relative'>
											<ImageWithFallback
												src={advertising.image.url}
												fallbackImage='/static/facebook600x315.png'
												alt={advertising.title[lng]}
												width={advertising.image.width}
												height={advertising.image.height}
												style={{
													width: '100%',
													height: '55vh',
													objectFit: 'cover',
												}}
											/>
										</Box>
										<Box
											sx={{
												background: '#e3e3e395',
												padding: 2,
												borderRadius: 1,
												position: 'absolute',
												top: { md: '25%', xs: '5%' },
												left: '5%',

												paddingBottom: 2,
												width: { md: '25%', xs: '85%', sm: '45%' },
												backdropFilter: 'blur(5px)',
											}}
										>
											<Typography variant='TitleSection'>
												{advertising.title[lng]}
											</Typography>
											<Typography variant='BodySection' color='primary.main'>
												{advertising.description[lng]}
											</Typography>
										</Box>
									</Box>
								</div>
							);
						})}
					</Slider>
				</Grid>
			</Grid>
		</Container>
	);
};
