'use client';
import { useClientTranslation } from '@negotium/common';
import {
	Alert,
	AlertTitle,
	Box,
	Container,
	Grid,
	List,
	Paper,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Diversity1Icon from '@mui/icons-material/Diversity1';

import { IRecipients } from '@negotium/models';
import {
	RecipientsCardShow,
	RecipientsWidget,
	useNotification,
} from '@negotium/componentsCustomer';
import { getRecipients } from '@negotium/libCustomer';

type ChangePasswordProps = {
	lng: 'es' | 'en';
};

export default function ChangePassword({ lng }: ChangePasswordProps) {
	const { t } = useClientTranslation(lng, ['recipients']);

	const { setErrorNotification } = useNotification();
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<Array<IRecipients> | []>([]);
	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		getRecipients(10, 0)
			.then((result) => {
				setData([...result.docs]);
				setLoading(false);
			})
			.catch((err) => {
				setErrorNotification(err.message);
				setLoading(false);
			});
	}, [refresh]);

	return (
		<Paper elevation={2}>
			<Container
				maxWidth='xl'
				sx={{
					pt: 1,
					pb: 5,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<Typography
							variant='TitleSection'
							fontSize='clamp(1rem,3vw,1.8rem)'
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Diversity1Icon
								fontSize='large'
								color='primary'
								sx={{ marginRight: '1rem' }}
							/>
							{t('myRecipients')}
						</Typography>
					</Grid>
					<Grid item xs={4} justifyContent='flex-end' display='flex'>
						<RecipientsWidget
							type='Add'
							lng={lng}
							onCallBack={() => setRefresh(!refresh)}
						/>
					</Grid>
					{data.length === 0 && !loading && (
						<Grid item xs={12}>
							<Alert
								sx={{
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
									svg: { width: '3rem', height: '3rem' },
								}}
								title='test'
								color='info'
								severity='info'
							>
								<AlertTitle
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									{t('noElement')}
								</AlertTitle>
								<Box padding='1rem'>
									<RecipientsWidget
										type='Add'
										lng={lng}
										onCallBack={() => setRefresh(!refresh)}
									/>
								</Box>
							</Alert>
						</Grid>
					)}
					{data.length > 0 && (
						<Grid item xs={12}>
							<List>
								{!loading &&
									data.map((item) => (
										<RecipientsCardShow
											key={item.recipientId}
											onCallBack={() => setRefresh(!refresh)}
											lng={lng}
											recipient={item}
										/>
									))}
							</List>
						</Grid>
					)}
				</Grid>
			</Container>
		</Paper>
	);
}
