import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { ICategoriesShow } from '@negotium/models';
import React from 'react';
import { ImageWithFallback } from '../ImageWithFallback';
import Link from 'next/link';

type CardCategoryProps = {
	lng: 'es' | 'en';
	category: ICategoriesShow;
};

const CardCategory = ({ lng, category }: CardCategoryProps) => {
	const { t } = useClientTranslation(lng, ['common']);

	return (
		<Card
			sx={{
				background: 'white',
				borderRadius: 1,
				width: '90%',
				m: 'auto',
				minHeight: '450px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<Box width={{ xs: '100%', md: '100%' }}>
				<ImageWithFallback
					src={category.image.url}
					fallbackImage='/static/facebook600x315.png'
					alt={category.name[lng]}
					width={category.image.width}
					height={category.image.height}
					style={{ width: '100%', height: 'auto' }}
				/>
			</Box>
			<CardContent>
				<Typography gutterBottom variant='TitleCard' component='div'>
					{category.name[lng]}
				</Typography>
			</CardContent>
			<CardActions
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
				}}
			>
				<Link
					href={`${lng}/catalog?categoriesId=${category.categoriesId}`}
					style={{
						textDecoration: 'none',
					}}
				>
					<Button size='small' variant='contained'>
						{t('seeProducts')}
					</Button>
				</Link>
			</CardActions>
		</Card>
	);
};

export default CardCategory;
