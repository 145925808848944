'use client';
import { encryptAES, useClientTranslation } from '@negotium/common';
import {
	Container,
	FormControl,
	FormGroup,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Paper,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import useUserChangePass from './useUserChangePass';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IUserChangePassword } from '@negotium/models';
import { useNotification } from '@negotium/componentsCustomer';
import { changePasswordAdminUser } from '@negotium/libCustomer';
import { useRouter } from 'next/navigation';

type ChangePasswordProps = {
	lng: 'es' | 'en';
};

export default function ChangePasswordPage({ lng }: ChangePasswordProps) {
	const { t } = useClientTranslation(lng, ['users']);
	const router = useRouter();
	const [loading, setLoading] = useState<boolean>(false);

	const [showPassword, setShowPassword] = useState(false);
	const [showRePassword, setShowRePassword] = useState(false);
	const [showOldPassword, setShowOldPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowRePassword = () => setShowRePassword((show) => !show);
	const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const { setErrorNotification, setSuccessNotification } = useNotification();

	const initialValues = {
		oldPassword: '',
		newPassword: '',
		rePassword: '',
	};

	const onSubmitForm = async (values: IUserChangePassword): Promise<void> => {
		setLoading(true);
		if (values.newPassword && process.env.NEXT_PUBLIC_ENCRYPT_KEY) {
			values.newPassword = encryptAES(
				values.newPassword,
				process.env.NEXT_PUBLIC_ENCRYPT_KEY
			);
		}

		changePasswordAdminUser(values.newPassword, values.oldPassword || '')
			.then(() => {
				setSuccessNotification(t('passwordChanged'));
				setLoading(false);
				router.push('/account');
			})
			.catch((err) => {
				setErrorNotification(err.message);
				setLoading(false);
			});
	};
	const { formik, isLoading } = useUserChangePass(
		initialValues,
		onSubmitForm,
		lng
	);

	return (
		<Paper elevation={2}>
			<Container
				maxWidth='xl'
				sx={{
					pt: 1,
					pb: 5,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							variant='TitleSection'
							fontSize='clamp(1rem,3vw,1.8rem)'
						>
							{t('changePassword')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<form onSubmit={formik.handleSubmit}>
							<Grid
								container
								rowSpacing={2}
								columnSpacing={2}
								width={{
									md: '60%',
									xs: '90%',
								}}
								margin='auto'
							>
								<Grid item xs={12}>
									<FormGroup>
										<FormControl>
											<InputLabel htmlFor='oldPassword' color='primary'>
												{t('oldPassword')}
											</InputLabel>
											<OutlinedInput
												id='oldPassword'
												name='oldPassword'
												label={t('oldPassword')}
												type={showOldPassword ? 'text' : 'password'}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton
															aria-label='toggle oldPassword visibility'
															onClick={handleClickShowOldPassword}
															onMouseDown={handleMouseDownPassword}
															edge='end'
														>
															{showOldPassword ? (
																<VisibilityOff color='primary' />
															) : (
																<Visibility color='primary' />
															)}
														</IconButton>
													</InputAdornment>
												}
												color='primary'
												placeholder='********'
												value={formik.values.oldPassword}
												onChange={(e) => formik.handleChange(e)}
												onBlur={formik.handleBlur}
												error={
													formik.touched.oldPassword &&
													Boolean(formik.errors.oldPassword)
												}
												fullWidth={false}
												margin='dense'
											/>
											{formik.errors.oldPassword &&
												formik.touched.oldPassword && (
													<FormHelperText sx={{ color: '#d32f2f' }}>
														{t(formik.errors.oldPassword)}
													</FormHelperText>
												)}
										</FormControl>
									</FormGroup>
								</Grid>{' '}
								<Grid item xs={12}>
									<FormGroup>
										<FormControl>
											<InputLabel htmlFor='newPassword' color='primary'>
												{t('newPassword')}
											</InputLabel>
											<OutlinedInput
												id='newPassword'
												name='newPassword'
												label={t('newPassword')}
												type={showPassword ? 'text' : 'password'}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															edge='end'
														>
															{showPassword ? (
																<VisibilityOff color='primary' />
															) : (
																<Visibility color='primary' />
															)}
														</IconButton>
													</InputAdornment>
												}
												color='primary'
												placeholder='********'
												value={formik.values.newPassword}
												onChange={(e) => formik.handleChange(e)}
												onBlur={formik.handleBlur}
												error={
													formik.touched.newPassword &&
													Boolean(formik.errors.newPassword)
												}
												fullWidth={false}
												margin='dense'
											/>
											{formik.errors.newPassword &&
												formik.touched.newPassword && (
													<FormHelperText sx={{ color: '#d32f2f' }}>
														{t(formik.errors.newPassword)}
													</FormHelperText>
												)}
										</FormControl>
									</FormGroup>
								</Grid>
								<Grid item xs={12}>
									<FormGroup>
										<FormControl>
											<InputLabel htmlFor='rePassword' color='primary'>
												{t('rePassword')}
											</InputLabel>
											<OutlinedInput
												id='rePassword'
												label={t('rePassword')}
												type={showRePassword ? 'text' : 'password'}
												color='primary'
												endAdornment={
													<InputAdornment position='end'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={handleClickShowRePassword}
															onMouseDown={handleMouseDownPassword}
															edge='end'
														>
															{showRePassword ? (
																<VisibilityOff color='primary' />
															) : (
																<Visibility color='primary' />
															)}
														</IconButton>
													</InputAdornment>
												}
												placeholder='********'
												value={formik.values.rePassword}
												onChange={(e) => formik.handleChange(e)}
												onBlur={formik.handleBlur}
												error={
													formik.touched.rePassword &&
													Boolean(formik.errors.rePassword)
												}
												fullWidth={false}
												margin='dense'
											/>
											{formik.errors.rePassword &&
												formik.touched.rePassword && (
													<FormHelperText sx={{ color: '#d32f2f' }}>
														{t(formik.errors.rePassword)}
													</FormHelperText>
												)}
										</FormControl>
									</FormGroup>
								</Grid>
								<Grid item xs={12}>
									<LoadingButton
										fullWidth
										type='submit'
										autoFocus
										loading={isLoading || loading}
										disabled={isLoading || loading}
										loadingPosition='end'
										title={t('updated')}
										variant='ButtonNavbarActive'
										sx={{
											borderRadius: 1,
											mt: 1.5,
										}}
										aria-disabled={isLoading || loading}
									>
										{t('updated')}
									</LoadingButton>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</Container>
		</Paper>
	);
}
