'use client';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ThemeBasic from '../../utils/theme';
import * as React from 'react';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
export interface IArrowSliderProps {
	right: boolean;
	onClick?: () => void;
}

const ThemeArrow = createTheme(ThemeBasic, {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					background: ThemeBasic.palette.white?.main,
					width: '45px',
					minWidth: '45px',
					height: '45px',
					padding: '5px',
					borderRadius: '50%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',

					position: 'absolute',
					zIndex: 99,
					cursor: 'pointer',
					top: '19dvh',
					svg: {
						color: ThemeBasic.palette.secondary?.main,
					},
					'&:hover': {
						background: ThemeBasic.palette.secondary?.main,
						svg: {
							color: ThemeBasic.palette.white?.main,
						},
					},
					'@media (max-width: 500px)': {
						background: ThemeBasic.palette.secondary?.main,
						svg: {
							color: ThemeBasic.palette.white?.main,
						},
					},
				},
			},
		},
	},
});

export default function ArrowSlider({
	right = false,
	onClick,
}: IArrowSliderProps) {
	return (
		<ThemeProvider theme={ThemeArrow}>
			<Button
				sx={{
					...(right
						? {
								right: '-5%',
						  }
						: {
								left: '-5%',
						  }),
				}}
				onClick={onClick}
			>
				{right ? (
					<KeyboardArrowRightRoundedIcon
						sx={{
							width: '50px',
							height: '50px',
						}}
					/>
				) : (
					<KeyboardArrowLeftRoundedIcon
						sx={{
							width: '50px',
							height: '50px',
						}}
					/>
				)}
			</Button>
		</ThemeProvider>
	);
}
