'use client';
import { useClientTranslation } from '@negotium/common';
import {
	Alert,
	AlertTitle,
	Container,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { IOrderShow } from '@negotium/models';
import {
	LoadingRectangle,
	useNotification,
} from '@negotium/componentsCustomer';
import { getPaidOrdersByAuthUser } from '@negotium/libCustomer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import Link from 'next/link';
import dayjs from 'dayjs';

type OrderHistoryProps = {
	lng: 'es' | 'en';
};

export default function OrderHistory({ lng }: OrderHistoryProps) {
	const { t } = useClientTranslation(lng, ['orders', 'common']);

	const { setErrorNotification } = useNotification();
	const [loading, setLoading] = useState<boolean>(true);
	const [ordersHistory, setOrdersHistory] = useState<Array<IOrderShow> | []>(
		[]
	);

	useEffect(() => {
		setLoading(true);
		getPaidOrdersByAuthUser()
			.then((result) => {
				setOrdersHistory([...result.docs]);
				setLoading(false);
			})
			.catch((err) => {
				setErrorNotification(err.message);
				setLoading(false);
			});
	}, []);

	return (
		<Paper elevation={2}>
			<Container
				maxWidth='xl'
				sx={{
					pt: 1,
					pb: 5,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							variant='TitleSection'
							fontSize='clamp(1rem,3vw,1.8rem)'
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<ShoppingCartIcon
								fontSize='large'
								color='primary'
								sx={{ marginRight: '1rem' }}
							/>
							{t('orderHistory')}
						</Typography>
					</Grid>
					{loading &&
						[...Array(3).keys()].map((item) => (
							<Grid item xs={12} md={4} key={item}>
								<LoadingRectangle width={'100%'} />
							</Grid>
						))}

					{ordersHistory.length === 0 && !loading && (
						<Grid item xs={12}>
							<Alert color='info' severity='info'>
								<AlertTitle
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									{t('userAuthNotOrders')}
								</AlertTitle>
							</Alert>
						</Grid>
					)}
					{!loading && ordersHistory.length > 0 && (
						<Grid item xs={12}>
							<TableContainer
								sx={{
									background: 'white',
									borderRadius: '10px',
								}}
							>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>{t('orderNumber')}</TableCell>
											<TableCell>{t('orderDate')}</TableCell>
											<TableCell>{t('orderStatus')}</TableCell>
											<TableCell align='right'>{t('orderTotal')}</TableCell>
											<TableCell align='right'>
												{t('actions', { ns: 'common' })}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{ordersHistory.map((order) => (
											<TableRow key={order.orderId}>
												<TableCell>{order.consecutiveNumber}</TableCell>
												<TableCell>
													{dayjs(order.createdAt)
														.locale(lng)
														.format('DD, MMMM YYYY hh:mm a')}
												</TableCell>
												<TableCell>{t(order.status)}</TableCell>
												<TableCell align='right'>
													{formatCurrency(order.totalPrice)}
												</TableCell>
												<TableCell align='right'>
													<IconButton color='info'>
														<Link href={`/order/${order.orderId}`}>
															<VisibilityIcon />
														</Link>
													</IconButton>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					)}
				</Grid>
			</Container>
		</Paper>
	);
}
