import Notification from './Notification';
import Navbar from './Navbar';
import MenuProfile from './MenuProfile';
import Profile from './Profile';
import RecipientsCardShow from './RecipientsCardShow';
import RecipientsWidget from './RecipientsWidget';
import DeleteButton from './DeleteButton';
import RecipientsProfile from './RecipientsProfile';
import ChangePasswordPage from './ChangePasswordPage';
import FilterCategoryProduct from './FilterCategoryProduct';
import WishList from './WishList';
import OrderHistory from './OrderHistory';
import { SectionPopularProducts } from './SectionPopularProducts';
import { SectionCategories } from './SectionCategories';
import { SectionRecentProducts } from './SectionRecentProducts';

import { LoadingSideBar, LoadingTable, LoadingRectangle } from './Skeleton';
import { useNotification, NotificationProvider } from './ErrorContext';
import ArrowSlider from './ArrowSlider';
import CardCategory from './CardCategory';
import { CardProduct } from './CardProduct';
import { Hero } from './Hero';
import { Advertising } from './Advertising';
import { ProductDetails } from './ProductDetails';

export {
	Navbar,
	Notification,
	useNotification,
	NotificationProvider,
	MenuProfile,
	Profile,
	LoadingSideBar,
	LoadingTable,
	LoadingRectangle,
	RecipientsCardShow,
	RecipientsWidget,
	DeleteButton,
	RecipientsProfile,
	ChangePasswordPage,
	OrderHistory,
	FilterCategoryProduct,
	WishList,
	SectionPopularProducts,
	SectionCategories,
	ArrowSlider,
	CardCategory,
	SectionRecentProducts,
	CardProduct,
	Hero,
	Advertising,
	ProductDetails,
};
