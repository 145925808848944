import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormGroup,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Slide,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
	FormControl,
	Divider,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useNotification } from '../ErrorContext';
import { v4 } from 'uuid';

import { AxiosError } from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import useRegisterFormData from './hooks/useRegisterFormData';
import { IPublicRegister } from '@negotium/models';
import {
	encryptAES,
	GenericServices,
	useClientTranslation,
} from '@negotium/common';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

type RegisterProps = {
	open: boolean;
	onClose: () => void;
	onOpenLogin: () => void;
	onOpenActivateAccount: (email: string) => void;
	lng: 'es' | 'en';
};

export const Register = ({
	open,
	onClose,
	lng = 'es',
	onOpenLogin,
	onOpenActivateAccount,
}: RegisterProps) => {
	const { t } = useClientTranslation(lng, ['users', 'common']);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [loading, setLoading] = useState(false);
	const { setErrorNotification, setSuccessNotification } = useNotification();

	const [showPassword, setShowPassword] = useState(false);
	const [showRePassword, setShowRePassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowRePassword = () => setShowRePassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const initialValues = {
		userId: v4(),
		name: '',
		lastName: '',
		email: '',
		phone: '',
		password: '',
		rePassword: '',
		ci: '',
	};

	const onSubmit = async (values: IPublicRegister): Promise<void> => {
		try {
			setLoading(true);

			let password: string = values.password;
			if (values?.password && process.env.NEXT_PUBLIC_ENCRYPT_KEY) {
				password = encryptAES(
					values.password,
					process.env.NEXT_PUBLIC_ENCRYPT_KEY
				);
			}
			await GenericServices('publicRegister', [], {
				...values,
				password,
			});
			setSuccessNotification(t('activationEmailSent', { ns: 'common' }));
			setLoading(false);
			onOpenActivateAccount(values.email);
			onClose();
		} catch (err) {
			if (err instanceof AxiosError) {
				setErrorNotification(t(`${err?.response?.data.message}`));
			}
			setLoading(false);
		}
	};

	const { formik, isLoading } = useRegisterFormData(
		initialValues,
		onSubmit,
		lng
	);

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-describedby='alert-dialog-slide-description'
			fullScreen={fullScreen}
			PaperProps={{
				sx: {
					backgroundColor: theme.palette.background.default,
					py: 2,
					borderRadius: '0',
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						backdropFilter: 'blur(10px)',
						background: (theme) => theme.palette.secondary.light,
					},
				},
			}}
		>
			<DialogTitle>
				<Typography
					variant='TitleSection'
					textAlign='center'
					fontSize='clamp(1.5rem,3.6vw, 2rem)'
				>
					{t('register')}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<FormGroup>
								<TextField
									color='secondary'
									label={t('name')}
									id='name'
									placeholder='Ej. John'
									fullWidth={false}
									margin='dense'
									name='name'
									value={formik.values.name}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helperText={formik.touched.name && formik.errors.name}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormGroup>
								<TextField
									color='secondary'
									label={t('lastName')}
									id='lastName'
									placeholder='Ej. Doe'
									fullWidth={false}
									margin='dense'
									name='lastName'
									value={formik.values.lastName}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={
										formik.touched.lastName && Boolean(formik.errors.lastName)
									}
									helperText={formik.touched.lastName && formik.errors.lastName}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<TextField
									color='secondary'
									label={t('email')}
									id='email'
									placeholder='user@NEXT_PUBLIC_DOMAIN.xxx'
									fullWidth={false}
									margin='dense'
									name='email'
									value={formik.values.email}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormGroup>
								<TextField
									color='secondary'
									label={t('phone')}
									id='phone'
									placeholder=''
									fullWidth={false}
									margin='dense'
									name='phone'
									value={formik.values.phone}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.phone && Boolean(formik.errors.phone)}
									helperText={formik.touched.phone && formik.errors.phone}
								/>
							</FormGroup>
						</Grid>{' '}
						<Grid item xs={12} md={6}>
							<FormGroup>
								<TextField
									color='secondary'
									label={t('ci')}
									id='ci'
									placeholder=''
									fullWidth={false}
									inputProps={{
										maxLength: 11,
										minLength: 11,
									}}
									margin='dense'
									name='ci'
									value={formik.values.ci}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={formik.touched.ci && Boolean(formik.errors.ci)}
									helperText={formik.touched.ci && formik.errors.ci}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormGroup>
								<FormControl>
									<InputLabel htmlFor='password' color='secondary'>
										{t('password')}
									</InputLabel>
									<OutlinedInput
										id='password'
										label={t('password')}
										type={showPassword ? 'text' : 'password'}
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'
												>
													{showPassword ? (
														<VisibilityOff color='secondary' />
													) : (
														<Visibility color='secondary' />
													)}
												</IconButton>
											</InputAdornment>
										}
										color='secondary'
										placeholder='********'
										value={formik.values.password}
										onChange={(e) => formik.handleChange(e)}
										onBlur={formik.handleBlur}
										error={
											formik.touched.password && Boolean(formik.errors.password)
										}
										fullWidth={false}
										margin='dense'
									/>
									{formik.errors.password && formik.touched.password && (
										<FormHelperText sx={{ color: '#d32f2f' }}>
											{t(formik.errors.password)}
										</FormHelperText>
									)}
								</FormControl>
							</FormGroup>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormGroup>
								<FormControl>
									<InputLabel htmlFor='rePassword' color='secondary'>
										{t('rePassword')}
									</InputLabel>
									<OutlinedInput
										id='rePassword'
										label={t('rePassword')}
										type={showRePassword ? 'text' : 'password'}
										color='secondary'
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickShowRePassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'
												>
													{showRePassword ? (
														<VisibilityOff color='secondary' />
													) : (
														<Visibility color='secondary' />
													)}
												</IconButton>
											</InputAdornment>
										}
										placeholder='********'
										value={formik.values.rePassword}
										onChange={(e) => formik.handleChange(e)}
										onBlur={formik.handleBlur}
										error={
											formik.touched.rePassword &&
											Boolean(formik.errors.rePassword)
										}
										fullWidth={false}
										margin='dense'
									/>
									{formik.errors.rePassword && formik.touched.rePassword && (
										<FormHelperText sx={{ color: '#d32f2f' }}>
											{t(formik.errors.rePassword)}
										</FormHelperText>
									)}
								</FormControl>
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<LoadingButton
								fullWidth
								type='submit'
								autoFocus
								loading={loading || isLoading}
								disabled={loading || isLoading}
								loadingPosition='end'
								title={t('register')}
								variant='ButtonNavbarActive'
								sx={{
									borderRadius: 1,
									mt: 1.5,
								}}
								aria-disabled={isLoading || loading}
							>
								{t('register')}
							</LoadingButton>
						</Grid>
						<Grid xs={12} item>
							<Divider
								sx={{
									borderColor: (theme) => theme.palette.secondary.light,
									borderWidth: 1,
									mb: 2,
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Button
								autoFocus
								onClick={() => onOpenLogin()}
								variant='ButtonActionText'
							>
								{t('alreadyHaveAnAccount', { ns: 'common' })}
							</Button>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
};
