'use client';
import { Box, ButtonGroup, ListItem, ListItemText } from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { IRecipients } from '@negotium/models';

import RecipientsWidget from '../RecipientsWidget';
import DeleteButton from '../DeleteButton';

export default function RecipientsCardShow({
	lng = 'es',
	recipient,
	onCallBack,
}: {
	recipient: IRecipients;
	onCallBack: () => void;
	lng: 'es' | 'en';
}) {
	const { t } = useClientTranslation(lng, ['recipients']);
	const deleteElement = () => {};

	return (
		<ListItem
			sx={{
				marginBottom: 2,
				padding: 2,
				borderRadius: 2,
				backgroundColor: '#f9f9f9',
				border: '1px solid',
				borderColor: 'divider',
			}}
			secondaryAction={
				<ButtonGroup>
					<RecipientsWidget
						type='Edit'
						id={recipient.recipientId}
						lng={lng}
						onCallBack={onCallBack}
					/>
					<DeleteButton
						onCallback={() => deleteElement()}
						isDisabled={false}
						alertMessage={t('confirmationCarriersDeleteMessage')}
						lng={lng}
					/>
				</ButtonGroup>
			}
		>
			<Box>
				<ListItemText
					id={recipient.recipientId}
					primary={recipient.fullName}
					secondary={`${recipient.address}, ${recipient.municipality}, ${recipient.province}`}
				/>{' '}
				<ListItemText secondary={`${t('email')}: ${recipient.email}`} />
				<ListItemText secondary={`${t('phone')}: ${recipient.phone}`} />
			</Box>
		</ListItem>
	);
}
