'use client';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	ButtonGroup,
	Chip,
	Container,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import React, { useEffect, useState } from 'react';
import { useNotification } from '../ErrorContext';
import { LoadingRectangle } from '../Skeleton';
import {
	IImageShow,
	IProductCatalog,
	IProductCatalogDetails,
} from '@negotium/models';
import {
	addProductCartShop,
	addUserToWhishList,
	getProductDetails,
	getRelationsProductsByCategories,
} from '@negotium/libCustomer';
import { ImageWithFallback } from '../ImageWithFallback';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useContextCartShop } from '../ContextCartShop';
import { CardProduct } from '../CardProduct';

type ProductDetailsProps = {
	lng: 'es' | 'en';
	productId: string;
};

export const ProductDetails = ({ lng, productId }: ProductDetailsProps) => {
	const { t } = useClientTranslation(lng, ['products', 'catalog']);
	const [productDetails, setProductDetails] =
		useState<IProductCatalogDetails | null>(null);

	const [amount, setAmount] = useState<number>(1);

	const { changeCountProducts, cartShopId, saveCartShopIdLocalStorage } =
		useContextCartShop();
	const [imageSelected, setImageSelected] = useState<IImageShow | null>(null);
	const [products, setProducts] = useState<Array<IProductCatalog>>([]);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { setSuccessNotification, setErrorNotification } = useNotification();

	useEffect(() => {
		if (productId && isLoading) {
			getProductDetails(productId)
				.then((result) => {
					setProductDetails(result.data);
					setImageSelected(result.data?.productImage || null);
					setIsLoading(false);
				})
				.catch(() => {
					setErrorNotification(t('errorToGetProduct'));
					setIsLoading(false);
				});
		}
	}, [productId, isLoading]);

	useEffect(() => {
		if (
			productDetails?.productCategories &&
			productDetails?.productCategories?.length > 0
		) {
			getRelationsProductsByCategories(productDetails.productCategories)
				.then((result) => {
					setProducts(result.docs);
				})
				.catch(() => {
					setErrorNotification(t('errorToGetProductRelationsByCategories'));
				});
		}
	}, [productDetails?.productCategories]);

	const handleIncrement = () => {
		setAmount(amount + 1);
	};

	const handleDecrement = () => {
		if (amount > 1) {
			setAmount(amount - 1);
		}
	};

	function handlerAddProduct(storageUniqueId: string, amount: number) {
		addProductCartShop({ amount, storageUniqueId, cartId: cartShopId })
			.then((response) => {
				changeCountProducts();
				saveCartShopIdLocalStorage(response.data as string);
				setSuccessNotification(
					t('addedProductToCartShopSuccessfully', { ns: 'catalog' })
				);
			})
			.catch(() => {
				setErrorNotification(
					t('errorToAddedProductToCartShop', { ns: 'catalog' })
				);
			});
	}

	function handleSave() {
		if (productDetails) {
			handlerAddProduct(productDetails?.storageUniqueId, amount);
		}
	}
	function addOrRemoveFavorite(storageUniqueId: string) {
		addUserToWhishList(storageUniqueId)
			.then((result) => {
				setIsLoading(true);
				setSuccessNotification(
					t(
						result?.data
							? 'successToProductAddedWishList'
							: 'successToProductRemoveWishList'
					)
				);
			})
			.catch(() => {
				setErrorNotification(t('errorToProductAddedWishList'));
			});
	}

	function setWishListUserLogged(data: boolean, index: number) {
		const aux = products;
		aux[index] = {
			...aux[index],
			whishListUserLogged: data,
		};
		setProducts([...aux]);
	}

	if (isLoading) {
		return (
			<Container
				maxWidth='xl'
				sx={{
					my: 3,
				}}
			>
				<Grid container spacing={1} marginTop={0.5}>
					<Grid item xs={12}>
						<LoadingRectangle height={'50vh'} width={'100%'} />
					</Grid>
				</Grid>
			</Container>
		);
	}

	if (!productDetails) {
		return (
			<Container
				maxWidth='xl'
				sx={{
					my: 3,
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<Alert severity='info'>
							<AlertTitle>{t('productNotFound')}</AlertTitle>
						</Alert>
					</Grid>
				</Grid>
			</Container>
		);
	}

	return (
		<Container
			maxWidth='xl'
			sx={{
				my: 5,
			}}
		>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper
						elevation={4}
						sx={{
							p: 3,
							borderRadius: 0.4,
							background: (theme) => theme.palette.white?.main,
						}}
					>
						<Grid container spacing={2}>
							<Grid item xs={12} md={5}>
								<Box
									sx={{
										background: (theme) =>
											theme.palette.white?.main || '#ffffff',
										borderRadius: 2,
										overflow: 'hidden',
										boxShadow:
											'0px 0px 10px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 7px 14px rgba(0, 0, 0, 0.1)',
									}}
								>
									<Box width={{ xs: '100%', md: '100%' }}>
										<ImageWithFallback
											src={imageSelected?.url || ''}
											fallbackImage='/static/facebook600x315.png'
											alt={productDetails?.productName[lng] || ''}
											width={imageSelected?.width || 100}
											height={imageSelected?.height || 100}
											style={{ width: '100%', height: '400px' }}
										/>
									</Box>
								</Box>

								<Box display='flex'>
									{productDetails?.productImageGallery.map((image) => {
										return (
											<Box
												key={image.url}
												sx={{
													mx: 1,
													my: 1,
													width: 100,
													height: 100,
													backgroundImage: `url(${image.url})`,
													backgroundSize: 'cover',
													backgroundPosition: 'center',
													cursor: 'pointer',
													background: (theme) =>
														theme.palette.white?.main || '#ffffff',
													borderRadius: 2,
													overflow: 'hidden',
													boxShadow:
														'0px 0px 10px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 7px 14px rgba(0, 0, 0, 0.1)',
												}}
												onClick={() => setImageSelected(image)}
											>
												<ImageWithFallback
													src={image?.url || ''}
													fallbackImage='/static/facebook600x315.png'
													alt={image?.url || ''}
													width={image?.width || 100}
													height={image?.height || 100}
													style={{ width: '100%', height: '100%' }}
												/>
											</Box>
										);
									})}
								</Box>
							</Grid>
							<Grid item xs={12} md={7}>
								<Box
									display='flex'
									justifyContent='space-between'
									alignItems='center'
									flexDirection={{ xs: 'column', md: 'row' }}
								>
									<Typography variant='TitleSection'>
										{productDetails?.productName[lng] || ''}
									</Typography>
									{productDetails?.productTag[lng] && (
										<Box display='flex' justifyContent='flex-end'>
											<Chip
												label={productDetails?.productTag[lng]}
												color='success'
												variant='filled'
											/>
										</Box>
									)}
								</Box>
								<Box>
									<Typography variant='BodySection'>
										{productDetails?.productDetails[lng] || ''}
									</Typography>
								</Box>
								<List
									sx={{
										height: '350px',
										overflowY: 'auto',
										overflowX: 'hidden',
									}}
								>
									{productDetails.attributes?.map((attribute) => {
										return (
											<ListItem key={attribute.attributeId}>
												<ListItemText
													primary={attribute.attributeName[lng] || ''}
													secondary={attribute.attributeValue?.join(', ')}
												/>
											</ListItem>
										);
									})}
								</List>

								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<ButtonGroup
											sx={{
												height: '30px',
											}}
											variant='text'
											aria-label={t('changeAmountOfProduct')}
											title={t('changeAmountOfProduct')}
										>
											<Button
												onClick={handleDecrement}
												aria-label={t('decrementAmount')}
												title={t('decrementAmount')}
												disabled={amount === 1}
												color='primary'
												variant='outlined'
												sx={{ width: '2.8rem' }}
											>
												-
											</Button>
											<TextField
												type='number'
												value={amount}
												inputProps={{
													sx: {
														minHeight: '30px',
														py: 0,
														px: 1,
														borderRadius: 0,
														textAlign: 'center',
														justifyContent: 'center',
														display: 'flex',
														'-moz-appearance': 'textfield',
														'::-webkit-outer-spin-button': {
															'-webkit-appearance': 'none',
															margin: 0,
														},
														'::-webkit-inner-spin-button': {
															'-webkit-appearance': 'none',
															margin: 0,
														},
													},
												}}
												onChange={(e) => setAmount(Number(e.target.value))}
												sx={{
													width: '2.8rem',

													borderRadius: 0,
													'&>*': {
														'&.MuiOutlinedInput-root': {
															borderRadius: 0,
														},
														fieldset: {
															borderColor: (theme) =>
																theme.palette.primary.main,
														},
													},
												}}
											/>
											<Button
												onClick={handleIncrement}
												aria-label={t('incrementAmount')}
												title={t('incrementAmount')}
												sx={{ width: '2.8rem' }}
												color='primary'
												variant='outlined'
											>
												+
											</Button>
										</ButtonGroup>
										<IconButton
											sx={{
												ml: 1,
											}}
											aria-label={t('addCartShop')}
											title={t('addCartShop')}
											size='large'
											color='warning'
											onClick={() => handleSave()}
										>
											<AddShoppingCartIcon fontSize='large' />
										</IconButton>
									</Box>

									<IconButton
										aria-label={t('addToWishList')}
										color='primary'
										size='large'
										title={t('addToWishList')}
										onClick={() =>
											addOrRemoveFavorite(productDetails?.storageUniqueId || '')
										}
									>
										{productDetails?.whishListUserLogged ? (
											<FavoriteIcon fontSize='large' />
										) : (
											<FavoriteBorderIcon fontSize='large' />
										)}
									</IconButton>
								</Box>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Typography variant='TitleSection'>
						{t('relationsProducts')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						{products.map((product, index) => (
							<Grid
								item
								xs={12}
								sm={6}
								md={3}
								key={product.productId}
								sx={{
									minHeight: '100%',
								}}
							>
								<CardProduct
									product={product}
									lng={lng}
									onAddProduct={handlerAddProduct}
									setWishListUserLogged={(data) =>
										setWishListUserLogged(data, index)
									}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};
