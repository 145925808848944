'use client';
import React, { MouseEvent, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Image from 'next/image';
import { usePathname, useRouter } from 'next/navigation';
import { signOut } from '../../app/lib/auth/actions';
import { ListItemButton } from '@mui/material';

import Link from 'next/link';
import { MenuLink } from '@negotium/models';
import { useClientTranslation, useSessionClient } from '@negotium/common';
import { pages } from '../../utils/constant';
import { useNotification } from '../ErrorContext';
import { Login } from '../Login';
import { Register } from '../Register';
import { useContextGeneral } from '../ContextGeneral';

const settingsLogout: Array<MenuLink> = [
	{ label: 'Iniciar sesión', pathname: '/signIn', validPermission: 'login' },
	{ label: 'Registrarse', pathname: '/signUp', validPermission: 'login' },
];
const settingsAuth: Array<MenuLink> = [
	{ label: 'Mi cuenta', pathname: '/account', validPermission: 'login' },
];

function Navbar({ lng }: { lng: string }) {
	const router = useRouter();
	const pathname = usePathname();

	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const { t } = useClientTranslation(lng, ['common']);
	const user = useSessionClient();
	const { setErrorNotification, setSuccessNotification } = useNotification();
	const { showLogin: openLogin, setShowLogin: setOpenLogin } =
		useContextGeneral();
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [, setOpenForgetPassword] = useState<boolean>(false);
	const [, setOpenActivateAccount] = useState<null | {
		email: string;
		code: string;
	}>(null);

	const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleLogout = async () => {
		signOut()
			.then(() => {
				router.push(`/${lng}`);
				setSuccessNotification(t('sessionClosedSuccessfully'));
				router.push('/');
			})
			.catch(() => {
				setErrorNotification(t('sessionClosedError'));
			});
		handleCloseUserMenu();
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const noIncludesPage = [
		'/es/activateAccount',
		'/es/signIn',
		'/es/signUp',
		'/es/forgetPassword',
		'/es/forgetPassword/changeClientPassword',
		'/es/403',
	];

	if (noIncludesPage.includes(pathname)) {
		return null;
	}
	return (
		<AppBar
			position='static'
			sx={{
				height: '7vh',
				background: (theme) => theme.palette.white?.main || 'white',
			}}
		>
			<Container maxWidth='xl'>
				<Toolbar
					disableGutters
					sx={{
						justifyContent: 'space-between',
					}}
				>
					<Link href={`/${lng}`} hrefLang={lng}>
						<Box sx={{ width: { xs: '70%', md: '100%' } }}>
							<Image
								src='/static/logo-hotizontal192x70.png'
								width={192}
								height={70}
								alt={process.env.NEXT_PUBLIC_NAME || ''}
								style={{ width: '100%', height: 'auto' }}
							/>
						</Box>
					</Link>

					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size='large'
							aria-label='account of current user'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={handleOpenNavMenu}
							color='inherit'
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							{pages.map((page) => (
								<MenuItem key={page.pathname} onClick={handleCloseNavMenu}>
									<Typography textAlign='center'>{page.label}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<Box
						display={{
							md: 'none',
							xs: 'flex',
						}}
					>
						<Image
							src='/static/favicon/256x256.png'
							width={80}
							height={80}
							alt='BNC'
						/>
					</Box>
					<Box
						display='flex'
						justifyContent='space-around'
						sx={{
							display: { xs: 'none', md: 'flex' },
						}}
					>
						{pages.map((page) => (
							<Link href={`${page.pathname}`} key={page.pathname}>
								<ListItemButton
									className='ListItemButton'
									selected={page.pathname === pathname}
								>
									{t(page.label)}
								</ListItemButton>
							</Link>
						))}
					</Box>

					<Box sx={{ flexGrow: 0, alignItems: 'center' }}>
						{/* <Button
								title={t('changeLanguage')}
								onClick={handlerChangeLang}
								variant='ButtonNavbarAction'
								startIcon={<LanguageIcon />}
							>
								{lng}
							</Button> */}
						<Button
							title={user ? t('menuUser') : t('registerOrLogin')}
							onClick={
								user ? (e) => handleOpenUserMenu(e) : () => setOpenLogin(true)
							}
							variant='ButtonNavbarAction'
						>
							<AccountCircleIcon />
						</Button>

						<Menu
							sx={{ mt: '45px' }}
							id='menu-appbar'
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							{user && user.permissions.includes('dashAdmin') ? (
								<MenuItem
									onClick={() => {
										router.push(
											`${process.env.NEXT_PUBLIC_DOMAIN_ADMIN}/backoffice/es`
										);
										handleCloseNavMenu();
										handleCloseUserMenu();
									}}
								>
									<Typography textAlign='center'>
										{t('admin', { ns: 'common' })}
									</Typography>
								</MenuItem>
							) : null}
							{(user === null ? settingsLogout : settingsAuth).map(
								(setting) => {
									return (
										<MenuItem
											key={setting.pathname}
											onClick={() => {
												router.push(setting.pathname);
												handleCloseNavMenu();
												handleCloseUserMenu();
											}}
										>
											<Typography textAlign='center'>
												{setting.label}
											</Typography>
										</MenuItem>
									);
								}
							)}
							{user ? (
								<MenuItem
									onClick={() => {
										handleLogout();
										handleCloseUserMenu();
									}}
								>
									<Typography textAlign='center'>
										{t('logout', { ns: 'common' })}
									</Typography>
								</MenuItem>
							) : null}
						</Menu>
					</Box>
				</Toolbar>
			</Container>
			<Login
				open={openLogin}
				onClose={() => setOpenLogin(false)}
				lng={lng as 'es' | 'en'}
				onOpenRegister={() => {
					setOpenRegister(true);
					setOpenLogin(false);
				}}
				onOpenForgetPassword={() => {
					setOpenForgetPassword(true);
					setOpenLogin(false);
				}}
			/>
			<Register
				open={openRegister}
				onClose={() => setOpenRegister(false)}
				lng={lng as 'es' | 'en'}
				onOpenLogin={() => {
					setOpenLogin(true);
					setOpenRegister(false);
				}}
				onOpenActivateAccount={(email) => {
					setOpenActivateAccount({
						email: email || '',
						code: '',
					});
				}}
			/>
		</AppBar>
	);
}
export default Navbar;
