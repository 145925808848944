'use client';
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { IPortraitCreate } from '@negotium/models';
import { useNotification } from '../ErrorContext';
import { getPortraitsActives } from '@negotium/libCustomer';
import { ImageWithFallback } from '../ImageWithFallback';
import Slider from 'react-slick';
// import dynamic from 'next/dynamic';

type HeroProps = {
	lng: 'es' | 'en';
};

// const ArrowSlider = dynamic(
// 	() =>
// 		import('@negotium/componentsCustomer').then((module) => module.ArrowSlider),
// 	{ ssr: false }
// );

export const Hero = ({ lng }: HeroProps) => {
	const { t } = useClientTranslation(lng, ['common']);

	const [portraits, setPortraits] = useState<Array<IPortraitCreate>>([]);

	const [loadingPortraits, setLoadingPortraits] = useState<boolean>(true);
	const { setErrorNotification } = useNotification();

	useEffect(() => {
		if (loadingPortraits) {
			getPortraitsActives()
				.then((response) => {
					setPortraits(response.docs);
					setLoadingPortraits(false);
				})
				.catch(() => {
					setLoadingPortraits(false);
					setErrorNotification(t('errorGetPortraits'));
				});
		}
	}, [loadingPortraits]);

	if (portraits.length === 0) {
		return null;
	}

	const settings = {
		dots: true,
		infinite: portraits.length > 1,
		speed: 500,
		autoPlay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		// nextArrow: <ArrowSlider right={true} />,
		// prevArrow: <ArrowSlider right={false} />,
	};

	return (
		<Container
			maxWidth='xl'
			disableGutters
			sx={{
				background: (theme) => theme.palette.primary.main,

				marginTop: '1rem',
			}}
		>
			<Grid container>
				<Grid item xs={12}>
					<Slider {...settings}>
						{[...portraits].map((portrait) => {
							return (
								<Box
									key={portrait.portraitId}
									position='relative'
									width={{ xs: '100%', md: '100%' }}
								>
									<Box
										width={{ xs: '100%', md: '100%' }}
										position='relative'
										key={portrait.portraitId}
									>
										<ImageWithFallback
											src={portrait.image.url}
											fallbackImage='/static/facebook600x315.png'
											alt={portrait.title[lng]}
											width={portrait.image.width}
											height={portrait.image.height}
											style={{ width: '100%', height: 'auto' }}
										/>
									</Box>
									<Box
										sx={{
											background: '#e3e3e395',
											padding: 2,
											borderRadius: 1,
											position: 'absolute',
											top: { md: '25%', xs: '5%' },
											left: '5%',

											paddingBottom: 2,
											width: { md: '25%', xs: '85%', sm: '45%' },
											backdropFilter: 'blur(5px)',
										}}
									>
										<Typography variant='TitleSection'>
											{portrait.title[lng]}
										</Typography>
										<Typography variant='BodySection' color='primary.main'>
											{portrait.description[lng]}
										</Typography>
									</Box>
								</Box>
							);
						})}
					</Slider>
				</Grid>
			</Grid>
		</Container>
	);
};
