'use client';
import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { ICategoriesShow } from '@negotium/models';
import { groupListCategories } from '../../app/lib/categories/actions';
import { useSearchParams } from 'next/navigation';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { LoadingRectangle } from '../Skeleton';
import { useCatalogContext } from '../ContextCatalog';

type FilterCategoryProductProps = {
	lng: 'es' | 'en';
};

function FilterCategoryProduct({ lng }: FilterCategoryProductProps) {
	const { t } = useClientTranslation(lng, ['catalog']);
	const {
		categoriesSelected,
		handlerSelected,
		clearSelected,
		clearSelectedAndRouter,
	} = useCatalogContext()!;
	const [categories, setCategories] = useState<Array<ICategoriesShow>>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const searchParams = useSearchParams();

	useEffect(() => {
		if (loading) {
			groupListCategories({})
				.then((result) => {
					if (result?.docs) {
						setCategories([...result.docs]);
					}
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		}
	}, [loading]);

	useEffect(() => {
		if (searchParams.size > 0) {
			const categoriesId: string | null = searchParams.get('categoriesId');
			if (categoriesId) {
				handlerSelected(categoriesId);
			} else {
				clearSelected();
			}
		}
	}, [searchParams.size]);

	function handlerClickClear() {
		if (searchParams.size > 0) {
			clearSelectedAndRouter();
		} else {
			clearSelected();
		}
	}

	const handleToggle = (value: string) => () => {
		handlerSelected(value);
	};

	function renderCategories(categories: Array<ICategoriesShow>) {
		return (
			<List dense sx={{ width: '100%' }}>
				{categories.map((category) => {
					const labelId = `checkbox-list-secondary-label-${category.categoriesId}`;
					return (
						<>
							<ListItem
								key={category.categoriesId}
								secondaryAction={
									<Checkbox
										color='secondary'
										edge='end'
										onChange={handleToggle(category.categoriesId)}
										checked={categoriesSelected.includes(category.categoriesId)}
										inputProps={{ 'aria-labelledby': labelId }}
										sx={{
											color: (theme) => theme.palette.secondary?.main,
											'&.Mui-checked': {
												color: (theme) => theme.palette.secondary?.main,
											},
										}}
									/>
								}
								disablePadding
							>
								<ListItemButton>
									<ListItemText
										id={labelId}
										primary={category.name[lng as 'es' | 'en']}
										primaryTypographyProps={{
											color: 'secondary.main',
											fontSize: '1.1rem',
										}}
									/>
								</ListItemButton>
							</ListItem>
							{category.child?.length > 0 && (
								<Box
									sx={{
										pl: 2,
									}}
								>
									{renderCategories(category.child)}
								</Box>
							)}
						</>
					);
				})}
			</List>
		);
	}

	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%',
				borderRadius: '10px',
			}}
		>
			<Paper
				elevation={1}
				sx={{
					width: '100%',
					background: (theme) => theme.palette.white?.main || 'white',
					borderRadius: '10px',
				}}
			>
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					sx={{
						p: 1,
						background: (theme) => theme.palette.secondary.main,
						borderRadius: '10px',
					}}
				>
					<Typography
						variant='BodySection'
						fontSize='1.2rem'
						color='white.main'
					>
						{t('filterByCategory')}
					</Typography>
					<Button
						title={t('clearFilterCategory')}
						variant='contained'
						color='warning'
						size='small'
						disabled={categoriesSelected.length === 0}
						onClick={() => handlerClickClear()}
					>
						<FilterAltOffIcon />
					</Button>
				</Box>
				<Box
					sx={{
						p: 1,
					}}
				>
					{!loading &&
						categories &&
						categories.length > 0 &&
						renderCategories(categories)}
					{loading &&
						[...Array(3).keys()].map(() => (
							<Box marginTop={1} display='flex' justifyContent='space-between'>
								<LoadingRectangle width={'80%'} height='20px' />
								<LoadingRectangle width={'10%'} height='20px' />
							</Box>
						))}
				</Box>
			</Paper>
		</Box>
	);
}
export default FilterCategoryProduct;
